import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
export default {
  name: 'timeScreeningse',
  props: {
    NumberEs: {
      type: Number,
      default: false
    }
  },
  data: function data() {
    return {
      quaflat: 0
    };
  },
  watch: {
    NumberEs: function NumberEs(val) {
      return this.quaflat = val;
    }
  },
  // mounted() {
  //     this.Timeclick();
  // },
  methods: {
    getTimestamp: function getTimestamp(time) {
      //把时间日期转成时间戳
      return new Date(time).getTime() / 1000;
    },
    addEs: function addEs() {
      this.quaflat = '';
      this.$emit('screening', ['', '']);
    },
    Timeclick: function Timeclick(start, end) {
      this.quaflat = start;
      //开始时间
      var starttime = moment(moment().add(start, 'days').startOf('day').valueOf()).format('YYYY-MM-DD HH:mm:ss');
      var start_time = this.getTimestamp(starttime);
      //结束时间
      var endtime = moment(moment().add(end, 'days').endOf('day').valueOf()).format('YYYY-MM-DD HH:mm:ss');
      var end_time = this.getTimestamp(endtime);

      //向父组件传递参数
      this.$emit('screening', [start_time, end_time], this.quaflat);
    }
  }
};